<template>
    <div>
      <div class="row">
        <div style="background-image:url('../../../background.webp');
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          min-height: 60vh;"
        ></div>
      </div>

      <div class="row">
        <div v-if="loggedIn && neededFiles?.length" class="p-5">
          <div role="alert" class="d-flex flex-row justify-content-center rounded alert alert-info">
            <div class="content">
              You are required to upload the following files to complete your registration:
              <span class="btn btn-sm btn-dark text-uppercase ms-3" @click="filesModal = true">Upload</span>
              <ul><li v-for="file in neededFiles" class="fw-bold" :key="file">{{ file }}</li></ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-5">
        <div class="col-12 d-flex flex-row justify-content-center position-relative">
          <div class="text-center">
            <h1 class="fw-bold text-white">Welcome to Berilot Software Suite</h1>
            <h3 class="color-gray-500">We are simplifying business processes for organizations by introducing commonly used tools.</h3>
            <h2 v-if="!loggedIn" class="color-gray-400 fw-bold mt-5">Get started on Berilot today</h2>
            <h2 v-else class="color-gray-400 fw-bold mt-5">{{ organization.name }}</h2>
            <div v-if="!loggedIn" class="ms-auto d-flex flex-row justify-content-center align-items-center">
              <div>
                <button class="btn btn-success btn-lg text-uppercase rounded m-2 me-3" @click="$router.push('/login')">Login</button>
              </div>
              <div>
                <button class="btn btn-dark btn-lg text-uppercase rounded m-2" @click="$router.push('/signup')">Sign Up</button>
              </div>
            </div>
            <div v-else class="ms-auto d-flex flex-row justify-content-center align-items-center">
              <div>
                <button class="btn btn-success btn-lg text-uppercase rounded m-2 me-3" @click="$router.push('/organization')">Manage</button>
              </div>
              <div>
                <button class="btn btn-primary btn-lg text-uppercase rounded m-2" @click="$router.push('/users')">Users</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row container">
        <div
            class="row col-xxl-8 offset-xxl-2 col-xl-10 offset-xl-1 pt-2 bg-dark bg-opacity-75 rounded-5 position-absolute d-flex flex-row justify-content-center"
            style="min-height: 450px;">
          <h2 class="text-center text-white mt-2 fw-light">Available Services</h2>
          <div
              @click="goToService(item.url)"
              class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3 m-xl-5 d-flex flex-column justify-content-center align-items-center services-card"
              style="border: 1px solid #ffffff; border-radius: 10px;"
              v-for="item in services">
            <div class="row align-items-center align-content-center">
              <div class="col-12 justify-content-center">
                <div class="text-center">
                  <img :src="'../../../' + item.image" width="250px" :alt="item.name">
                </div>
                <div class="font-size-125 fw-bold text-white text-center mt-2 text-uppercase">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Upload Files Modal -->
      <Modal :open="filesModal" @closed="filesModal = false" dialog-size="modal-lg">
        <div class="modal-header">
          <h5 class="modal-title m-0">Upload Files</h5>
          <button @click="filesModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div v-for="file in neededFiles" class="form-group col-md-6">
                <label for="duration" class="form-label fw-bold h5 mt-3">{{ file }}:</label>
                <input class="form-control" type="file" @input="handleFileUpload($event, file)" name="docs" accept=".jpeg,.png,.jpg,.pdf">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="filesModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" @click="uploadFiles">Submit</button>
        </div>
      </Modal>
      <!-- Upload Files Modal End -->

    </div>
</template>

<script>
    import formatNumber from "../utils/formatNumber";
    import formatDate from "../utils/formatDate";
    import {decrypt} from "@/utils/crypto";
    import notify from "@/utils/notify";
    import organizationService from "@/api/organizationService";
    import serviceService from "@/api/serviceService";

    export default {
      name: "Dashboard",
      emits:['confirm', 'cancel'],
      data() {
        return {
          loading: true,
          loggedIn: false,
          services: [],
          profile: {},
          organization: {
            files: [],
          },
          filesModal: false,
          orgFiles: [],
          requiredFiles: ['Registration Certificate', 'ID Card'],
          neededFiles: [],
          selectedFiles: [],
        }
      },

      methods: {
        formatDate,
        formatNumber,
        determineNeededFiles: function () {
          this.organization.files.forEach(file => {
            this.orgFiles.push(file.name)
          })
          this.requiredFiles.forEach(file => {
            if (!this.orgFiles.includes(file)) {
              this.neededFiles.push(file)
            }
          })
        },
        getServices: async function () {
          let services = sessionStorage.getItem('services')
          if (services) {
            this.services = JSON.parse(services)
          } else {
            this.services = await serviceService.getServices()
            sessionStorage.setItem('services', JSON.stringify(this.services))
          }
        },
        goToService: function (href) {
          window.open(href, '_blank')
        },
        handleFileUpload(file, name) {
          this.selectedFiles[name] = file
        },
        checkRedirect: function () {
          let next = sessionStorage.getItem('next')
          if (this.loggedIn && next !== null) {
            window.open(next, '_blank')
          }
          sessionStorage.removeItem('next')
        },
        uploadFiles : async function() {
          this.neededFiles.forEach(name => {
            if (this.selectedFiles[name] === undefined) {
              notify.alert.error('Please select a file for ' + name)
            } else {
              let fileReader = this.processFile(this.selectedFiles[name])
              fileReader.onload = async () => {
                if (typeof fileReader.result === 'string' && Object.keys(this.selectedFiles).length === this.neededFiles.length) {
                  let response = await organizationService.addOrganizationFile({
                    organization_id: this.organization.id,
                    name: name,
                    file: fileReader.result,
                  })
                  if (response) {
                    this.filesModal = false
                    await organizationService.getOrganization()
                    window.location.reload()
                  }
                }
              }
            }
          })
        },
        processFile : function(file) {
          const fileReader = new FileReader()
          const { files } = file.target
          if (files && files.length) {
            fileReader.readAsDataURL(files[0])
            return fileReader
          }
        },
      },

      created: async function () {
        await this.getServices()
        this.loggedIn = decrypt(sessionStorage.getItem('logged_in')) === 'true'
        this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')) ?? '{}')
        if (this.profile.email_verified_at === null) {
          this.$router.push('/change-password')
        }
        let orgData = sessionStorage.getItem('organization')
        if (orgData) {
          this.organization = JSON.parse(decrypt(orgData))
        }
        this.loading = false
        this.determineNeededFiles()
        this.checkRedirect()
      },

    }
</script>

<style scoped>
.services-card:hover {
  opacity: 60%;
  cursor:pointer;
  background-color: #17559d;
}
</style>
